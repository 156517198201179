import BellCurve from "src/components/custom/bell_curve/BellCurve"
import StateSavingChildComponent from "src/components/base/StateSavingChildComponent";


class BellCurveWidget extends StateSavingChildComponent {
    BELL_CURVE_MEAN: number = 50
    BELL_CURVE_STD: number = 15
    bellCurve: BellCurve
    maxHeightPercentage:  number
    maxWidthPercentage: number
    strokeWidth: number
    scalingFactor: number
    stepSize: number

    constructor(props: any) {
        super(props)
        this.maxHeightPercentage = props.maxHeightPercentage
        this.maxWidthPercentage = props.maxWidthPercentage
        this.strokeWidth = props.strokeWidth
        this.stepSize = props.stepSize

        this.bellCurve = new BellCurve(this.BELL_CURVE_MEAN, this.BELL_CURVE_STD)
        this.scalingFactor = this.computeScalingFactor()
    }

    computeScalingFactor() {
        let highestPoint = this.bellCurve.computeValue(this.BELL_CURVE_MEAN)
        return this.maxHeightPercentage / highestPoint
    }

    getBellCurveY(x: number) {
        let bellCurveValue = this.bellCurve.computeValue(x) * this.scalingFactor
        return (100 - bellCurveValue) + "%"
    }

    getBellCurveX(x: number) {
        let value = this.maxWidthPercentage / 100 * (x - 50) + 50
        return value + "%"
    }

    drawLine(x_location: number, colour: string = "white", className: string = "bell-curve-line") {
        return (
            <line 
                className={className}
                x1={this.getBellCurveX(x_location)} y1="100%" 
                x2={this.getBellCurveX(x_location)} y2={this.getBellCurveY(x_location)} 
                stroke={colour} 
                stroke-width={this.strokeWidth}
            />
        )
    }

    getBellCurveShapeLines() {
        let mappingLines = []
        for (let i=0; i<100; i+=this.stepSize) {
            mappingLines.push(this.drawLine(i, "black"))
        }
        return mappingLines

    }

    render() {
        return (
            <svg width="100%" height="100%">
                {this.getBellCurveShapeLines()}
            </svg>
        )
    }
}

export default BellCurveWidget
