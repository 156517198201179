import {Component} from "react"


class ValuationDescription extends Component<any> {
    render() {
        return (
            <div className="valuation-description"> 
                <svg width="100%" height="100%">
                    <text x="50%" y="65%" dominantBaseline="middle" textAnchor="middle">
                        Valuate your home
                    </text>
                </svg>
            </div>
        )
    }
}

export default ValuationDescription
