import _ from 'lodash'

import ValuatingComponent from 'src/components/base/ValuatingComponent'
import HouseValuationInputsPage from "src/components/house_valuation/house_valuation_inputs/HouseValuationInputsPage"
import HouseValuationResultsPage from "src/components/house_valuation/house_valuation_results/HouseValuationResultsPage"
import HouseValuationPageStates from "src/enums/HouseValuationPageStates"
import ValuationStateHelper from "src/utils/ValuationStateHelper"
import ValuationAPIHelper from "src/utils/ValuationAPIHelper"
import Validator from "src/validation/Validator"
import RedirectToLoginException from 'src/validation/exception_messages/RedirectToLoginException';
import ErrorHandler from 'src/components/errors/ErrorHandler';
import Loader from "src/components/general/Loading"
import ValuationDescription from "src/components/layout/ValuationDescription"


class HouseValuationPage extends ValuatingComponent {
    valuationAPIHelper : ValuationAPIHelper;
    validator: Validator;
    errorHandler: ErrorHandler;
    loader: Loader;

    constructor(props: any) {
        super(props);
        this.visibleStates = new Set([
            "comparableHomes",
            "street-address",
            "country",
            "county",
            "town",
            "current-house-condition",
            "pageState",
            "userVisibleError",
            "advancedOptions"
        ])
        this.states = {
            "userVisibleError": null,
            "street-address": "",
            "town": "",
            "country": "Ireland",
            "county": "",
            "current-house-condition": "50",
            "pageState": HouseValuationPageStates.INPUT,
            "comparableHomesInputField": null,
            "comparableHomesOutputField": null,
            "comparableHomes": {},
            "valuateInputField": null,
            "valuateOutputField": null,
            "advancedOptions": false
        }
        this.valuationAPIHelper = props.valuationAPIHelper
        this.validator = props.validator
        this.errorHandler = props.errorHandler
        this.loader = props.loader
        this.getAnyErrors = this.getAnyErrors.bind(this)
        this.valuate = this.valuate.bind(this);
        this.newValuation = this.newValuation.bind(this);
        this.whereNeccessaryRestorePastStates = this.whereNeccessaryRestorePastStates.bind(this);
    }

    render() {
        this.whereNeccessaryRestorePastStates()

        let errorState = this.getAnyErrors()
        let content = this.getPageContent()

        return (
            <div>
                {errorState}
                {content}
            </div>
        );
    }

    getPageContent() {
        if (this.getState("pageState") == HouseValuationPageStates.INPUT) {
            return (
                <div className='valuations-home-page'>
                    <ValuationDescription/>
                    <HouseValuationInputsPage parent={this}/>
                </div>
            )
        }
        if (this.getState("pageState") == HouseValuationPageStates.RESULT) {
            return (
                <div>
                    <HouseValuationResultsPage parent={this}/>
                </div>
            )
        }
        if (this.getState("pageState") == HouseValuationPageStates.LOADING) {
            return this.loader.render("Genarating Valuation")
        }
    }

    whereNeccessaryRestorePastStates() {
        this.valuationAPIHelper.stateHandler.restoreStatesPostLogin(this)
    }

    getAnyErrors() {
        let error = this.getState("userVisibleError")
        if (error == null) {
            return
        }
        return this.errorHandler.render(error)
    }

    newValuation() {
        this.assignState("pageState", HouseValuationPageStates.LOADING)

        let comparableHomes = this.getState("comparableHomesOutputField")
        ValuationStateHelper.setComparableHomes(this, comparableHomes["comparable_homes"])
        this.assignState("pageState", HouseValuationPageStates.INPUT)
    }


    async valuate() {
        const initialState = this.getState("pageState")
        try {
            await this.performValuation()
        } catch (error: any) {
            this.assignState("pageState", initialState)
            if(error instanceof RedirectToLoginException){
                return
            }
            this.assignState("userVisibleError", error)
            return
        }
        this.assignState("userVisibleError", null)
    }

    async performValuation() {
        this.assignState("userVisibleError", null)
        this.assignState("pageState", HouseValuationPageStates.LOADING)

        this.valuationAPIHelper.stateHandler.setStates(this.states)
        await this.getAndStoreComparableHomes()
        await this.getAndStoreValuation()

        this.assignState("pageState", HouseValuationPageStates.RESULT)
    }

    async getAndStoreComparableHomes() {
        let newComparableHomesInputField = ValuationStateHelper.getComparableHomesInputField(this)
        this.validator.validateComparableHomesInput(newComparableHomesInputField)

        let comparableHomes = this.getState("comparableHomesOutputField")
        if (!_.isEqual(newComparableHomesInputField, this.getState("comparableHomesInputField"))) {
            comparableHomes = await this.valuationAPIHelper.getComparableHomes(newComparableHomesInputField)
            this.assignState("comparableHomesInputField", newComparableHomesInputField)
            this.assignState("comparableHomesOutputField", comparableHomes)
            ValuationStateHelper.setComparableHomes(this, comparableHomes["comparable_homes"])
        }
        this.validator.validateComparableHomesOutput(comparableHomes)
    }

    async getAndStoreValuation() {
        let newValuationInputField = ValuationStateHelper.getValuationInputField(this)
        this.validator.validateValuateInput(newValuationInputField)

        let valuation = this.getState("valuateOutputField")
        if (!_.isEqual(newValuationInputField, this.getState("valuateInputField"))) {
            valuation = await this.valuationAPIHelper.getValuation(newValuationInputField)
            this.assignState("valuateInputField", newValuationInputField)
            this.assignState("valuateOutputField", valuation)
        }
        this.validator.validateValuateOutput(valuation)
    }
}

export default HouseValuationPage
