import BellCurveWidget from "src/components/custom/bell_curve/BellCurveWidget"
import Format from "src/utils/Format"


class ValuationResultBellCurveWidget extends BellCurveWidget {
    annotationYPosition : number
    annotationLowerYPosition : number
    annotationHorizontalSpacing: number
    annotationWidth: number = 150
    annotationHeight: number = 45
    buffer: number = 15 
    classNamePrefix: string

    constructor(props: any) {
        super(props)
        this.maxWidthPercentage = 90
        this.maxHeightPercentage = 60
        this.annotationYPosition = 50
        this.annotationLowerYPosition = this.annotationYPosition + this.annotationHeight + this.buffer
        this.scalingFactor = this.computeScalingFactor()
        this.strokeWidth = 1 
        this.stepSize = 0.5

        this.annotationHorizontalSpacing = this.annotationWidth / 2 + this.buffer
        this.classNamePrefix = "valuation-result-bell-curve-"
    }

    drawMarker (xLocation: number, label: string) {
        let marker = []
        let className = this.classNamePrefix + "marker"
        marker.push(super.drawLine(xLocation, "black", className))
        marker.push(<circle className={className} cx={this.getBellCurveX(xLocation)} cy={this.getBellCurveY(xLocation)} r="3"/>)
        return marker
    }

    drawConnector(location: number, isSpacingRequired: boolean = false) {
        let connector = []
        let position = this.getBellCurveX(location)
        let yPostion = this.annotationYPosition
        if (isSpacingRequired) {
            yPostion = this.annotationLowerYPosition
        }
        let className = this.classNamePrefix + "connector"
        connector.push(<line x1={position} y1={Format.getPosition(yPostion)} x2={position} y2="100%" stroke="black" stroke-dasharray="1,3,1" stroke-width="0.275" className={className}/>)

        return connector
    }

    drawAnnotation(xLocation: number, label: string, valuationText: string, valuationId: string, isSpacingRequired: boolean = false) {
        let yPosition = this.annotationYPosition
        if (isSpacingRequired) {
            yPosition = this.annotationLowerYPosition
        }
        let markClassName = this.classNamePrefix + "annotation-mark"
        let textClassName = this.classNamePrefix + "annotation-text"
        let annotation = []
        let xPosition = this.getBellCurveX(xLocation)
        let backgroundTranslation = "translate(" + - this.annotationWidth / 2 + ", " + - this.annotationHeight / 2  + ")"
        let circleTranslation = "translate(0, " + this.annotationHeight/2  + ")"
        annotation.push(<rect x={xPosition} y={Format.getPosition(yPosition)} width={Format.getPosition(this.annotationWidth)} height={Format.getPosition(this.annotationHeight)} rx="4pt" ry="4pt" transform={backgroundTranslation} fill="white" textAnchor="middle" dominantBaseline="middle" />)
        annotation.push(<text x={xPosition} y={Format.getPosition(yPosition - 10)} className={textClassName} font-size="10px" textAnchor="middle" dominantBaseline="middle">{label}</text>)
        annotation.push(<text id={valuationId} x={xPosition} y={Format.getPosition(yPosition + 5)} className={textClassName} font-size="20px" textAnchor="middle" dominantBaseline="middle">{valuationText}</text>)
        annotation.push(<circle cx={xPosition} cy={Format.getPosition(yPosition)} r="2" transform={circleTranslation} className={markClassName}/>)
        return annotation
    }

    drawValuation(valuation: any, label: string, valuationId: string, isSpacingRequired: boolean = false) {
        let valuationDrawing = []

        let state = parseFloat(valuation["state"]) * 100
        let value = Format.getFormattedCurrency(valuation["value"])

        valuationDrawing.push(this.drawConnector(state, isSpacingRequired))
        valuationDrawing.push(this.drawMarker(state, "state"))
        valuationDrawing.push(this.drawAnnotation(state, label, value, valuationId, isSpacingRequired))

        return valuationDrawing
    }

    render () {
        let prefix = "valuate" 
        let currentValuationElementId = prefix + "-current_valuation"

        let valuateOutputField = this.getState("valuateOutputField");
        let longtermValuation = valuateOutputField["longterm_valuation"]
        let currentValuation = longtermValuation["current_valuation"]

        return (
            <svg width="100%" height="100%">
                {this.getBellCurveShapeLines()}
                {this.drawValuation(currentValuation, "", currentValuationElementId)}
            </svg>
        )
    }
}

export default ValuationResultBellCurveWidget
